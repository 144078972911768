// SYSTEM
export const SERVER_URL = `https://${process.env.REACT_APP_HOST}`;
export const APP_IMAGES = '/assets/images';
export const APP_API_FILES = `${SERVER_URL}/media`;

/** *************  COMMON ************** */
export const HOME = '/';
export const ERROR_PAGE = '/error';
export const HELP_CENTER = '/help-center';
export const POLITIC = '/privacy-policy';
export const LICENSE = '/license-agreement';
export const SITE_LABEL = 'app.io';
export const SITE = `https://${SITE_LABEL}`;

// SOCIALS
export const APP_PHONE = '+7 499 999-99-99';
export const APP_EMAIL = 'info@app.io';
export const TELEGRAM = 'https://t.me/appname';
export const WHATSUP = 'https://wa.me/79999999999';

// AUTH
export const AUTH = '/auth';
export const UI = '/ui';
export const LOGIN = `${AUTH}/login`;
export const REGISTRATION = `${AUTH}/registration`;
export const RESET_PASSWORD = `${AUTH}/reset-password`;
export const RESET_PASSWORD_SUBMIT = `${AUTH}/reset-password-submit`;
export const SET_NEW_PASSWORD = `${AUTH}/new-password`;
export const PHONE_ACTIVATION = `${AUTH}/activation/phone/`;

/** *************  INTERFACE MAIN ROUTES ************** */
export const ORDERS = 'orders';
export const CATALOG = 'catalog';
export const MAILINGS = 'mailings';
export const CLIENTS = 'clients';
export const SETTINGS = 'settings';
export const ANALYTICS = 'analytics';
export const INTEGRATIONS = 'integrations';
export const LOYALTY = 'loyalty';

/** ****************  CATALOG PAGES  ******************* */
export const CATEGORIES = 'categories';
export const SUBCATEGORY = 'subcategory';
export const CATEGORIES_ADD = 'add';
export const CATEGORIES_EDIT = ':categoryId';

export const STORIES = 'stories';
export const STORIES_ADD = 'add';
export const STORIES_EDIT = ':storiesId';

export const PRODUCTS = 'products';
export const PRODUCTS_ADD = 'add';
export const PRODUCTS_EDIT = ':productId';

export const GROUP_ADDITIONS = 'group-modifications';
export const ADDITIONS = 'modifications';
export const ADDITIONS_EDIT = ':additionId';

/** ****************  SETTINGS PAGES  ******************* */

export const SETTINGS_MAIN = 'main';

export const SETTINGS_LOCATIONS = 'locations';
export const SETTINGS_LOCATIONS_ADD = 'add';
export const SETTINGS_LOCATIONS_EDIT = 'edit/:locationId';

export const SETTINGS_PAYMENT = 'payments';
export const SETTINGS_ACCOUNT = 'account';

export const INTERFACE = '/app';

/** ****************  CATALOG  ******************* */
export const INTERFACE_CATALOG = CATALOG;

export const INTERFACE_CATALOG_CATEGORIES = CATEGORIES;
export const INTERFACE_CATALOG_CATEGORIES_ADD = CATEGORIES_ADD;
export const INTERFACE_CATALOG_CATEGORIES_EDIT = CATEGORIES_EDIT;

export const INTERFACE_CATALOG_STORIES = STORIES;
export const INTERFACE_CATALOG_STORIES_ADD = STORIES_ADD;
export const INTERFACE_CATALOG_STORIES_EDIT = STORIES_EDIT;

export const INTERFACE_CATALOG_PRODUCTS = PRODUCTS;
export const INTERFACE_CATALOG_PRODUCTS_ADD = PRODUCTS_ADD;
export const INTERFACE_CATALOG_PRODUCTS_EDIT = PRODUCTS_EDIT;

export const INTERFACE_CATALOG_GROUP_ADDITIONS = GROUP_ADDITIONS;
export const INTERFACE_CATALOG_ADDITIONS = ADDITIONS;

/** ****************  SETTINGS  ******************* */
export const INTERFACE_SETTINGS = SETTINGS;

export const INTERFACE_SETTINGS_MAIN = SETTINGS_MAIN;

export const INTERFACE_SETTINGS_LOCATIONS = SETTINGS_LOCATIONS;
export const INTERFACE_SETTINGS_LOCATIONS_ADD = SETTINGS_LOCATIONS_ADD;
export const INTERFACE_SETTINGS_LOCATIONS_EDIT = SETTINGS_LOCATIONS_EDIT;

export const INTERFACE_SETTINGS_PAYMENTS = SETTINGS_PAYMENT;
export const INTERFACE_SETTINGS_ACCOUNT = SETTINGS_ACCOUNT;

/** ****************  OTHERS  ******************* */
export const INTERFACE_CLIENTS = CLIENTS;
export const INTERFACE_ANALYTICS = ANALYTICS;
export const INTERFACE_ORDERS = ORDERS;
export const INTERFACE_MAILINGS = MAILINGS;
export const INTERFACE_LOYALTY = LOYALTY;

export const INTERFACE_LOYALTY_POINTS = 'points';
export const INTERFACE_LOYALTY_GIFTS = 'gifts';
