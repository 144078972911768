export const PointsProductsActionTypes = {
  SET_FETCHING_POINTS_PRODUCTS: 'SET_FETCHING_POINTS_PRODUCTS',
  FETCH_POINTS_PRODUCTS_ERROR: 'FETCH_POINTS_PRODUCTS_ERROR',

  CLEAR_POINTS_PRODUCTS_SEARCH: 'CLEAR_POINTS_PRODUCTS_SEARCH',
  FETCH_POINTS_PRODUCTS_SEARCH_SUCCESS: 'FETCH_POINTS_PRODUCTS_SEARCH_SUCCESS',
  SET_POINTS_PRODUCTS_SEARCH_IS_EMPTY: 'SET_POINTS_PRODUCTS_SEARCH_IS_EMPTY',

  SET_FETCHING_POINTS_PRODUCTS_MODAL: 'SET_FETCHING_POINTS_PRODUCTS_MODAL',
  CLEAR_POINTS_PRODUCTS_MODAL: 'CLEAR_POINTS_PRODUCTS_MODAL',
  SET_POINTS_PRODUCTS_MODAL_IS_EMPTY: 'SET_POINTS_PRODUCTS_MODAL_IS_EMPTY',
  FETCH_POINTS_PRODUCTS_MODAL_SUCCESS: 'FETCH_POINTS_PRODUCTS_MODAL_SUCCESS',

  SET_FETCHING_POINTS_PRODUCT_SUBMITTING: 'SET_FETCHING_POINTS_PRODUCT_SUBMITTING',
  FETCH_POINTS_PRODUCT_DELETE_SUCCESS: 'FETCH_POINTS_PRODUCT_DELETE_SUCCESS',
  FETCH_POINTS_PRODUCT_UPDATE_SUCCESS: 'FETCH_POINTS_PRODUCT_UPDATE_SUCCESS',
  SET_FETCHING_POINTS_PRODUCT_UPDATE: 'SET_FETCHING_POINTS_PRODUCT_UPDATE',
  SET_FETCHING_POINTS_PRODUCT_DELETE: 'SET_FETCHING_POINTS_PRODUCT_DELETE',
};
