import { PointsProductsActionTypes } from 'redux/types/PointsProductsTypes';
import { UserActionTypes } from 'redux/types/UserTypes';

const INITIAL_STATE = {
  name: 'pointsProducts',
  searchList: null,
  modalList: [],
  modalListIsEmpty: [],
  isFetching: true,
  isFetchingModal: true,
  isSubmitting: false,
};

const productsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UserActionTypes.USER_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE };

  case PointsProductsActionTypes.SET_FETCHING_POINTS_PRODUCTS:
    return {
      ...state,
      isFetching: action.payload,
    };

  case PointsProductsActionTypes.FETCH_POINTS_PRODUCTS_SEARCH_SUCCESS:
    return {
      ...state,
      searchList: action.payload,
    };

  case PointsProductsActionTypes.CLEAR_POINTS_PRODUCTS_SEARCH:
    return {
      ...state,
      searchList: null,
    };

  case PointsProductsActionTypes.SET_FETCHING_POINTS_PRODUCTS_MODAL:
    return {
      ...state,
      isFetchingModal: action.payload,
    };

  case PointsProductsActionTypes.FETCH_POINTS_PRODUCTS_MODAL_SUCCESS:
    return {
      ...state,
      modalList: action.payload,
    };

  case PointsProductsActionTypes.SET_POINTS_PRODUCTS_MODAL_IS_EMPTY:
    return {
      ...state,
      modalListIsEmpty: action.payload,
    };

  case PointsProductsActionTypes.CLEAR_POINTS_PRODUCTS_MODAL:
    return {
      ...state,
      modalList: [],
      modalListIsEmpty: false,
    };

  case PointsProductsActionTypes.SET_FETCHING_POINTS_PRODUCT_SUBMITTING:
    return {
      ...state,
      isSubmitting: action.payload,
    };

  case PointsProductsActionTypes.FETCH_POINTS_PRODUCT_DELETE_SUCCESS:
    return {
      ...state,
      searchList: (state.searchList || []).filter((item) => item.id !== action.payload),
    };

  case PointsProductsActionTypes.FETCH_POINTS_PRODUCT_UPDATE_SUCCESS:
    return {
      ...state,
      searchList: (state.searchList || []).map((item) => (item.id === action.payload.id
        ? {
          ...item,
          formats: [
            {
              ...item.formats[0],
              points_price: action.payload.price,
              is_possible_buy_with_points: true,
            },
          ],
        } : item)),
    };

  default:
    return state;
  }
};

export default productsReducer;
