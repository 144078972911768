export const GiftsProductsActionTypes = {
  SET_FETCHING_GIFTS_PRODUCTS: 'SET_FETCHING_GIFTS_PRODUCTS',
  FETCH_GIFTS_PRODUCTS_ERROR: 'FETCH_GIFTS_PRODUCTS_ERROR',

  CLEAR_GIFTS_PRODUCTS_SEARCH: 'CLEAR_GIFTS_PRODUCTS_SEARCH',
  FETCH_GIFTS_PRODUCTS_SEARCH_SUCCESS: 'FETCH_GIFTS_PRODUCTS_SEARCH_SUCCESS',
  SET_GIFTS_PRODUCTS_SEARCH_IS_EMPTY: 'SET_GIFTS_PRODUCTS_SEARCH_IS_EMPTY',

  SET_FETCHING_GIFTS_PRODUCTS_MODAL: 'SET_FETCHING_GIFTS_PRODUCTS_MODAL',
  CLEAR_GIFTS_PRODUCTS_MODAL: 'CLEAR_GIFTS_PRODUCTS_MODAL',
  SET_GIFTS_PRODUCTS_MODAL_IS_EMPTY: 'SET_GIFTS_PRODUCTS_MODAL_IS_EMPTY',
  FETCH_GIFTS_PRODUCTS_MODAL_SUCCESS: 'FETCH_GIFTS_PRODUCTS_MODAL_SUCCESS',

  SET_FETCHING_GIFTS_PRODUCT_SUBMITTING: 'SET_FETCHING_GIFTS_PRODUCT_SUBMITTING',
  FETCH_GIFTS_PRODUCT_DELETE_SUCCESS: 'FETCH_GIFTS_PRODUCT_DELETE_SUCCESS',
  FETCH_GIFTS_PRODUCT_UPDATE_SUCCESS: 'FETCH_GIFTS_PRODUCT_UPDATE_SUCCESS',
  SET_FETCHING_GIFTS_PRODUCT_UPDATE: 'SET_FETCHING_GIFTS_PRODUCT_UPDATE',
  SET_FETCHING_GIFTS_PRODUCT_DELETE: 'SET_FETCHING_GIFTS_PRODUCT_DELETE',
};
