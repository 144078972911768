import axios from 'axios';
import * as PathTypes from 'constants/PathsTypes';
import {
  PRODUCTS,
  CATEGORIES,
} from 'constants/PathsTypes';
import StorageService from 'services/StorageService';
import history from 'history/configureHistory';
import store from 'redux/store/configureStore';

// redux
import { setNotificationError } from 'redux/actions/SystemActions';
import { OrderStatuses } from 'constants/OrdersTypes';

const LOCATIONS = 'trade_points';

const errorBeautifier = (error) => (error.hasOwnProperty('payload')
  ? error.payload.data.error
  : {
    name: 'Error',
    message: error.message,
    status: error.message.status,
  });

class ApiService {
  constructor() {
    this.client = axios.create({
      baseURL: `${PathTypes.SERVER_URL}/rest/`,
      timeout: 60 * 1000, // 1 min
    });
    this.paths = {
      auth: {
        GET_CODE: '/accounts/users/auth_request/',
        REGISTRATION_CONFIRM: '/accounts/users/auth_confirm/',
        GET_TOKEN: '/accounts/get_token/',
        LOGIN: '/accounts/users/me/',
        // LOGOUT: '/accounts/users/me/',
        EMAIL_CONFIRM: (uid) => `/accounts/email_verify_orders/${uid}/activate/`,
        CHANGE_PASSWORD: '/accounts/users/change_password/',
      },
      analytics: '/analytics',
      user: {
        REGISTRATION: '/auth/registration/',
        LOGIN: '/accounts/users/me/',
        LOGOUT: '/auth/logout/',
        RESET_PASSWORD: '/auth/reset/phone/',
        PHONE_ACTIVATION: '/auth/activation/phone/',
        RESET_PASSWORD_SUBMIT: '/auth/reset/phone/submit/',
        SET_NEW_PASSWORD: '/auth/set/password/',
        CHANGE_PASSWORD: '/auth/change/password/',
        CHANGE_AVATAR: '/auth/users/change_avatar/',
        NOTIFICATIONS: '/user_notify/',
        PROFILE_EDIT: '/accounts/users/edit/',
      },
      categories: {
        GLOBAL: '/global_categories/',
        GET_GLOBAL_SUBCATEGORIES: (id) => `/global_categories/${id}/categories/`,
        ROOT: `${CATEGORIES}/`,
        SWITCH_ACTIVE: (id) => `${CATEGORIES}/${id}/switch_active/`,
      },
      products: {
        ROOT: `${PRODUCTS}/`,
        POINTS: `${PRODUCTS}/for_points/`,
        GIFTS: '/free_from_order_price/',
        SWITCH_ACTIVE: (id) => `${PRODUCTS}/${id}/switch_active/`,
      },
      productFormats: { ROOT: '/product_formats/' },
      additions: {
        ROOT: 'additions/',
        GROUP: 'addition_groups/',
        GROUP_BY_ID: (id) => `addition_groups/${id}/`,
      },
      stories: {
        GROUP: '/group_stories/',
        GROUP_STORY_SWITCH_ACTIVE: (id) => `/group_stories/${id}/switch_active/`,
        GROUP_STORY_STORIES: (id) => `/group_stories/${id}/stories/`,
        STORIES: '/stories/',
      },
      locations: {
        ROOT: `${LOCATIONS}/`,
        SWITCH_ACTIVE: (id) => `${LOCATIONS}/${id}/switch_active/`,
        SCHEDULE: (id) => `${LOCATIONS}/${id}/schedule_elems/`,
        SCHEDULE_UPDATE: (id) => `/schedule_elems/${id}/`,
      },
      demos: { ROOT: '/demo_cases/' },
      mailings: {
        ROOT: '/push_group_orders/',
        SWITCH_ACTIVE: (id) => `${LOCATIONS}/${id}/switch_active/`,
      },
      orders: {
        ROOT: '/staff_orders/',
        [OrderStatuses.IN_PROCESS]: (id) => `/staff_orders/${id}/accept_company/`,
        [OrderStatuses.READY]: (id) => `/staff_orders/${id}/ready/`,
        [OrderStatuses.ON_WAY]: (id) => `/staff_orders/${id}/on_way/`,
        [OrderStatuses.ON_ADDRESS]: (id) => `/staff_orders/${id}/on_address/`,
        [OrderStatuses.DELIVERED]: (id) => `/staff_orders/${id}/delivered/`,
        [OrderStatuses.COMPLETED]: (id) => `/staff_orders/${id}/complete/`,
        [OrderStatuses.REJECTED_COMPANY]: (id) => `/staff_orders/${id}/reject_company/`,
        [OrderStatuses.NOT_DELIVERED]: (id) => `/staff_orders/${id}/not_delivered/`,
      },
      clients: { ROOT: '/company_clients/' },
      form: { REQUEST: '/form_orders/' },
      company: {
        UPDATE: '/companies/edit/',
        LINKS: '/company_links/',
        PAYMENTS: '/company_payment_settings/',
        PAYMENTS_METHOD_TOGGLE: (id) => `/company_payment_settings/${id}/switch_active/`,
      },
      app: {
        UPDATE: '/company_apps/edit/',
        THEMES: '/app_themes/',
        MENU_OPTIONS: '/app_menu_options/',
        MENU_OPTION_TOGGLE: (option) => `app_menu_options/${option}/switch_active/`,
        SEND_ON_MODERATION: '/company_apps/send_on_moderation/',
        CHECK_MODERATION_LIST: '/company_apps/moderation_checklist/',
        PUBLISH: '/company_apps/publish/',
      },
      devices: {
        ROOT: '/device/',
        GET: '/device_types/',
      },
      screenshots: {
        ROOT: '/company_apps_screenshots/',
        DELETE_BY_TYPE_ID: (id) => `/device_types/${id}/clear_app_content/`,
      },
      countries: { ROOT: '/countries/' },
    };
  }

  apiCall({
    url = '',
    method = 'GET',
    token = false,
    isToken,
    multipart,
    ...otherParams
  }) {
    let options = {
      url,
      method,
      headers: this.buildHeaders(token, isToken, multipart),
    };

    if (otherParams) {
      options = {
        ...options,
        ...otherParams,
      };
    }

    return this.client(options)
      .then(this.handleCommonSuccess)
      .catch(this.handleCommonError);
  }

  buildHeaders(token, isToken, multipart = false) {
    const headers = {
      'Content-type': multipart ? 'multipart/form-data' : 'application/json',
      Accept: `application/json; version=${process.env.REACT_APP_API_VERSION}`,
    };

    if (token || isToken) headers.Authorization = `Bearer ${isToken ? StorageService.getToken() : token}`;

    return headers;
  }

  handleCommonSuccess(response) {
    return Promise.resolve(response.data);
  }

  handleCommonError(error) {
    const { dispatch } = store;

    if (error && error.response) {
      const { data } = error.response;

      if (error.response.status === 401) {
        StorageService.removeToken();
        StorageService.removeUserSession();
        history.push(PathTypes.HOME, { setModal: { name: 'login' } });
      }

      if (error.response.status === 500) {
        dispatch(setNotificationError('Server error 500'));
      }
      else {
        dispatch(setNotificationError(data));
      }
    }

    // FOR DEBUG ONLY
    console.info('DEBUG ERROR START');
    console.warn('MESSAGE:', error && error.message);
    console.info('DEBUG ERROR END');

    return Promise.reject(
      error && error.response ? error.response : errorBeautifier(error),
    );
  }
}

export default new ApiService();
