export const FiltersActionTypes = {
  SET_FILTER: 'SET_FILTER',
  SET_FILTERS: 'SET_FILTERS',
  SET_OFFSET: 'SET_OFFSET',
  SET_LIMIT: 'SET_LIMIT',
  RESET_ALL_FILTERS: 'RESET_ALL_FILTERS',
};

export const FiltersNames = {
  filters: 'filters',
  filtersProducts: 'filtersProducts',
  filtersPointsProducts: 'filtersPointsProducts',
  filtersPointsProductsModal: 'filtersPointsProductsModal',
  filtersGiftsProductsModal: 'filtersGiftsProductsModal',
  filtersOrders: 'filtersOrders',
  filtersAnalytics: 'filtersAnalytics',
  filtersGroupAdditions: 'filtersGroupAdditions',
  filtersAdditions: 'filtersAdditions',
};

export const FiltersNamesTypes = {
  STATUSES: 'statuses',
  PERIOD: 'period',
  CATEGORIES: 'categories',
  SEARCH: 'search',
  LIMIT: 'limit',
  OFFSET: 'offset',
  ORDER_TYPE: 'order_type',
  LOCATION: 'location',
};

export const PERIOD_INIT = null;
export const CATEGORIES_INIT = null;
export const SEARCH_INIT = '';
export const OFFSET_INIT = 0;
export const LIMIT_INIT = 40;

export const PeriodsFilters = {
  LAST_3_DAYS: 'LAST_3_DAYS',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  LAST_MONTH: 'LAST_MONTH',
};

export const periodsFiltersModel = {
  [PeriodsFilters.LAST_3_DAYS]: {
    value: PeriodsFilters.LAST_3_DAYS,
    label: 'Последние 3 дня',
  },
  [PeriodsFilters.WEEK]: {
    value: PeriodsFilters.WEEK,
    label: 'Неделя',
  },
  [PeriodsFilters.MONTH]: {
    value: PeriodsFilters.MONTH,
    label: 'Этот месяц',
  },
  [PeriodsFilters.LAST_MONTH]: {
    value: PeriodsFilters.LAST_MONTH,
    label: 'Предыдущий месяц',
  },
};
